<template>
  <div class="profile">
    <div class="container">
      <b-row>
        <b-col md="6">
          <template v-if="$options.components['profile-show-edit-profile']">
            <profile-show-edit-profile />
          </template>
        </b-col>
        <b-col md="6">
          <template v-if="$options.components['profile-my-account-detail']">
            <profile-my-account-detail />
          </template>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "profile",
  components: {},
  data() {
    return {};
  },
  async mounted() {
    this.$store.commit("theme/updateTheme", {});
    this.$store.commit("theme/updateName", "");
    await this.$store.dispatch("address/fetchDeliveryAddress");
  },
  methods: {},
};
</script>

<style scoped></style>
